$modal-easing: cubic-bezier(0.2, 0.2, 0.3, 1);

@keyframes overlay {
    to {
        opacity: 1;
        background-color: rgba($color-gun-powder, 0.75);
    }
}

.c-modal {
    @include fixed(0 0 0 0); // need this to support iOS 15.3 and older
    @include scrollable();
    border: 0;
    width: 100%;
    max-width: $space-small;
    max-height: 98%;
    padding: $space-8 $space-4 $space-10;

    background: $color-white;
    border-radius: br('large');

    &:not(.c-modal--large) {
        @include container();
    }

    @include at-max($bp-tiny) {
        padding-bottom: $space-6;
    }

    &:focus {
        outline: none;
    }

    &::backdrop {
        background-color: transparent;
        animation: overlay 300ms $modal-easing forwards;
    }

    &.c-modal--no-overaly::backdrop {
        animation: none;
    }

    &.c-modal--large {
        @include at-max($bp-small) {
            width: 100%;
            min-height: 100%;
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.c-modal__close {
    @include absolute($space-2 $space-2 null null);
    font-size: $font-size-1;
    color: $color-true-v;
    background-color: $color-link-water;
    z-index: z('modal-close');

    &:hover,
    &:focus {
        background-color: $color-link-water;
    }

    &:focus {
        box-shadow: 0 0 1px 3px rgba($color-true-v, 0.3);
    }
}

.c-modal__inner {
    @include content-box($space-tiny);
    margin: auto; // Centering content inside modal

    & > *:first-child:not(.c-modal__icon) {
        // In case there is no icon above content,
        // we don't wanna overlapping with close button.
        margin-top: $space-2;
    }
}

.c-modal__title {
    @include t-display(3);
    font-weight: $font-bold;
    margin-bottom: $space-3;
    text-align: center;

    .c-modal & {
        color: $color-true-v;
    }

    .c-modal--large & {
        @include t-display(4);
    }
}

.c-modal__content {
    > p:last-child {
        margin-bottom: 0;
    }
}

.c-modal__actions {
    text-align: center;
    margin-top: $space-6;

    > * + * {
        margin-top: $space-4;
    }
}

.c-modal__icon {
    text-align: center;
    margin: 0 auto $space-4;

    > svg:not([width]) {
        @include size($icon-large);
    }

    .c-modal__title {
        font-size: $font-size-3;
        margin-bottom: 0;
        margin-left: $space-1;
    }

    &:has(.c-modal__title) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
