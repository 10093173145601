// Stying of markdown output
// Scoped with .s-markdown class on parent
.s-markdown {
    counter-reset: md-counter;

    h2 {
        margin-bottom: 1em;
        font-size: $font-size-4;
    }

    &.s-markdown--numbered h2 {
        counter-increment: md-counter;

        &:before {
            content: counter(md-counter) '. ';
        }
    }

    ol {
        list-style-type: upper-alpha;
    }

    ul {
        margin-left: $space-3;
        list-style-type: revert;

        ul {
            margin-bottom: $space-2;
        }
    }

    p + h2,
    ul + h2,
    ol + h2,
    table + h2 {
        margin-top: 1.5em;
    }

    p,
    li {
        line-height: $line-height-4;
    }

    address {
        text-align: center;
        margin-bottom: $space-6;
        font-size: $font-size-3;
    }

    [id='index'] + ul {
        margin-left: $space-4;

        li {
            margin-bottom: $space-1;
        }
    }

    .ccpa-table + table {
        border-spacing: 0;
        max-width: 100%;
        table-layout: fixed;

        thead {
            background-color: $color-athens-gray;
        }

        td,
        th {
            vertical-align: top;
            padding: $space-2;
        }

        th {
            text-align: left;
        }

        table,
        th,
        td {
            border: 1px solid $color-iron;
            border-collapse: collapse;
        }
    }
}

@media print {
    body {
        height: auto;
    }

    .u-flex-center {
        display: block !important;
    }

    [id='root'],
    .l-grid,
    .l-container {
        display: initial;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    main {
        height: auto;
        overflow: auto;
        padding: 0;
        position: static;
    }

    header,
    footer,
    .l-btn-helpdesk,
    .print-hide {
        display: none !important;
    }

    article,
    h1 {
        page-break-before: always;
    }

    @page {
        margin-top: 0.5in;
        margin-bottom: 1.5in;
    }

    @page :first {
        margin-top: 0;
    }
}
