.c-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    user-select: none;
    text-decoration: none;
    text-align: center;
    border-radius: br('pill');
    transition: all 0.2s;

    font-size: $font-size-3;
    font-weight: $font-bold;
    line-height: $line-height-1;
    padding: $space-2 $space-4;

    &[disabled],
    &:disabled {
        @include disabled(0.3);
        filter: none !important;
    }

    &:focus {
        outline: none;
    }

    &:not(.c-btn--icon) {
        min-width: 8ch;
    }
}

.c-btn--primary {
    @include ripple($color-melanie);
    color: $color-white;
    background: $gradient-base;
    overflow: hidden;

    &:not(.c-spinner):after {
        @include absolute(0 0 0 0);
        content: '';
        background: $gradient-dark;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: -2; // needs to be smaller than ripple z-index
        pointer-events: none;
    }

    &:hover,
    &:focus {
        color: $color-white;
        &:after {
            opacity: 1;
        }
    }

    &:focus {
        box-shadow: 0 0 1px 3px rgba($color-wisteria, 0.3);
    }

    &.c-spinner:after {
        border-top-color: $color-wisteria;
    }
}

.c-btn--secondary {
    @include ripple($color-biloba-flower);
    color: $color-white;
    background-color: $color-true-v;

    &:hover,
    &:focus {
        color: $color-white;
        background-color: $color-purple-heart;
    }

    &:focus {
        box-shadow: 0 0 1px 3px rgba($color-true-v, 0.3);
    }

    &.c-spinner:after {
        border-top-color: $color-purple-heart;
    }
}

.c-btn--inverted {
    @include ripple($color-biloba-flower);
    color: $color-true-v;
    background-color: $color-white;

    &:hover,
    &:focus {
        color: $color-true-v;
        background: $color-link-water;
    }

    &:focus {
        box-shadow: 0 0 1px 3px rgba($color-white, 0.3);
    }

    &.c-spinner:after {
        border-top-color: $color-purple-heart;
    }
}

.c-btn--outline {
    background: $color-white !important; // @TODO: remove important
    box-shadow: inset 0 0 0 2px currentcolor;

    &:after {
        display: none;
    }

    &.c-btn--primary {
        color: $color-wisteria;

        &:hover,
        &:focus {
            color: $color-eminence;
        }

        &:focus {
            box-shadow:
                inset 0 0 0 2px currentcolor,
                0 0 1px 3px rgba($color-wisteria, 0.3);
        }
    }

    &.c-btn--secondary {
        color: $color-true-v;

        &:hover,
        &:focus {
            color: $color-purple-heart;
        }

        &:focus {
            box-shadow:
                inset 0 0 0 2px currentcolor,
                0 0 1px 3px rgba($color-true-v, 0.3);
        }
    }

    &.c-btn--inverted {
        @include ripple($color-white);
        color: $color-white;

        &:hover,
        &:focus {
            color: $color-link-water;
        }

        &:focus {
            box-shadow:
                inset 0 0 0 2px currentcolor,
                0 0 1px 3px rgba($color-white, 0.3);
        }
    }
}

.c-btn--block {
    width: 100%;
    display: block;
}

.c-btn--large {
    min-height: $space-12;
}

a.c-btn--large {
    padding-top: $space-4;
    padding-bottom: $space-4;
}

.c-btn--raised {
    filter: drop-shadow(#{$shadow-200});

    &.c-btn--large {
        filter: drop-shadow(#{$shadow-300});
    }
}

.c-btn--icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: $space-2;

    &:not(.c-link) > * {
        margin: 0 $space-1;
    }

    > * + * {
        margin-left: 0;
    }

    > *:first-child:last-child {
        margin: 0;
    }

    svg {
        flex: none;
    }

    &.c-btn--block {
        padding-left: $space-6;
        padding-right: $space-6;

        svg {
            margin: 0;
        }

        > *:not(svg) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.c-btn--help {
    @include ripple($color-biloba-flower);

    @include at-max($bp-medium) {
        filter: drop-shadow(#{$shadow-500});

        &:hover,
        &:focus {
            filter: drop-shadow(0 0 2px rgba($color-black, 0.2));
        }
    }

    @include at-min($bp-medium) {
        background: $color-white;
        padding: $space-1;

        &:hover,
        &:focus {
            background: $color-athens-gray;
        }
    }
}

@keyframes floating {
    0%,
    100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10%);
    }
}

.c-btn--cta {
    font-size: $font-size-5;
    padding-left: $space-6;
    padding-right: $space-6;
    box-shadow: $shadow-300;

    &:not(:hover),
    &:not(:focus) {
        svg {
            animation: floating 1.3s infinite ease-out;
        }
    }
}

.c-btn--menu {
    @include ripple($color-true-v);
    font-size: $font-size-4;
    color: $color-wisteria;

    &.is-open {
        background: $color-white-lilac !important;
    }
}
