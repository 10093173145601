.c-icon {
    @include size(1em);
    display: inline-block;
    stroke-width: 0;
    stroke: currentcolor;
    fill: currentcolor;

    path:not([fill='none']) {
        fill: inherit !important;
    }
}

.c-icon--yes,
.c-icon--no {
    fill: $color-white;
    box-shadow: $shadow-10;
    border-radius: 50%;
    width: 22px;
    height: auto;
}
