// White star for the rating
$star: svg-url(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#fff" d="M15.524 6.464a.5.5 0 0 1 .952 0l1.881 5.791a.5.5 0 0 0 .476.346h6.09a.5.5 0 0 1 .294.904l-4.927 3.58a.5.5 0 0 0-.182.559l1.882 5.792a.5.5 0 0 1-.77.559l-4.926-3.58a.5.5 0 0 0-.588 0l-4.927 3.58a.5.5 0 0 1-.77-.56l1.883-5.791a.5.5 0 0 0-.182-.56l-4.927-3.579a.5.5 0 0 1 .294-.904h6.09a.5.5 0 0 0 .476-.346l1.881-5.791Z"/></svg>'
);

.c-star-rating {
    @include size($space-8);
    display: inline-flex;
    position: relative;
    border-radius: br('tiny');
    background-color: $color-mischka;
    overflow: hidden;
    margin: 0 ($space-1 * 0.5);

    &:after {
        @include absolute(0);
        content: '';
        background-image: $star;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    &.is-full {
        background-color: $color-wisteria;
    }

    &.is-decimal {
        &:before {
            @include absolute(0 null null 0);
            width: calc(var(--decimal, 0) * 1%);
            height: 100%;
            background-color: $color-wisteria;
            content: '';
        }
    }
}

.c-star-rating-table {
    border-spacing: 0;
    max-width: $bp-tiny * 1px;
    margin-left: auto;
    margin-right: auto;

    margin-top: $space-6;
    margin-bottom: $space-6;

    @include at-max($bp-small) {
        width: auto;
    }

    th {
        font-weight: $font-medium;
        font-size: $font-size-1;
        padding: $space-2;
    }

    td {
        vertical-align: middle;
        font-size: $font-size-2;
        padding: $space-1;

        @include at-min($bp-small) {
            font-size: $font-size-3;
            padding-bottom: $space-2;
        }
    }
}
