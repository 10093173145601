.u-bg-none {
    background-color: transparent !important;
}

.u-bg-white {
    background-color: $color-white !important;
}

.u-bg-black {
    background-color: $color-black !important;
}

.u-bg-true-v {
    background-color: $color-true-v !important;
}

.u-bg-brilliant-rose {
    background-color: $color-brilliant-rose !important;
}

.u-bg-gun-powder {
    background-color: $color-gun-powder !important;
}

.u-bg-purple-heart {
    background-color: $color-purple-heart !important;
}

.u-bg-royal-heath {
    background-color: $color-royal-heath !important;
}

.u-bg-link-water {
    background-color: $color-link-water !important;
}

.u-bg-biloba-flower {
    background-color: $color-biloba-flower !important;
}

.u-bg-storm-gray {
    background-color: $color-storm-gray !important;
}

.u-bg-santas-gray {
    background-color: $color-santas-gray !important;
}

.u-bg-iron {
    background-color: $color-iron !important;
}

.u-bg-athens-gray {
    background-color: $color-athens-gray !important;
}

.u-bg-alabaster {
    background-color: $color-alabaster !important;
}

.u-bg-mischka {
    background-color: $color-mischka !important;
}

.u-bg-wild-sand {
    background-color: $color-wild-sand !important;
}

.u-bg-anti-white {
    background-color: $color-anti-white !important;
}

.u-txt-white {
    color: $color-white !important;
}

.u-txt-black {
    color: $color-black !important;
}

.u-txt-true-v {
    color: $color-true-v !important;
}

.u-txt-brilliant-rose {
    color: $color-brilliant-rose !important;
}

.u-txt-wisteria {
    color: $color-wisteria !important;
}

// grays
.u-txt-storm-gray {
    color: $color-storm-gray !important;
}

.u-txt-santas-gray {
    color: $color-santas-gray !important;
}

.u-txt-iron {
    color: $color-iron !important;
}

.u-txt-athens-gray {
    color: $color-athens-gray !important;
}

.u-txt-alabaster {
    color: $color-alabaster !important;
}

.u-txt-carnation {
    color: $color-carnation !important;
}

.u-txt-french-gray {
    color: $color-french-gray !important;
}

.u-bg-shadow-5 {
    box-shadow: $shadow-5;
}
