// Default link style that can be applied to other elements.

.c-link {
    @include underline();
    color: $color-wisteria;
    padding: 0;
    transition: all 0.2s;

    &:hover,
    &:focus {
        color: $color-eminence;
    }

    &:focus {
        outline: dotted currentcolor 1.5px;
    }

    &[role='button'] {
        cursor: pointer;
    }
}

.c-link--inverted {
    color: $color-white;

    &:hover,
    &:focus {
        color: rgba($color-white, 0.7);
    }
}

.c-link--icon {
    display: inline-flex;
    align-items: center;
}

.c-link--clean {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
        color: inherit;
    }
}
