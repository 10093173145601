// Styles related to glider js plugin

.glider-contain {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.glider {
    margin: 0 auto;
    position: relative;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    transform: translateZ(0);
}

.glider-track {
    transform: translateZ(0);
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    z-index: 1;
}

.glider.draggable {
    user-select: none;
    cursor: grab;
}

.glider.draggable .glider-slide img {
    user-select: none;
    pointer-events: none;
}

.glider.drag {
    cursor: grabbing;
}

.glider-slide {
    user-select: none;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-left: $space-2;
    margin-right: $space-2;

    @include at-min($bp-medium) {
        margin-left: $space-4;
        margin-right: $space-4;
    }
}

.glider-slide {
    min-width: 10rem;
    display: flex;
    flex-direction: column;

    > p {
        margin-bottom: auto;
    }

    > footer {
        margin-top: $space-2;
    }
}

.glider-hide {
    opacity: 0;
}

.glider-controls {
    display: none;

    .glider ~ & {
        display: flex;
    }
}

.glider-controls {
    justify-content: center;
    align-items: center;
    margin-left: -1 * $space-2;
    margin-right: -1 * $space-2;

    @include at-min($bp-medium) {
        margin-top: $space-6;
    }
}

.glider-prev,
.glider-next {
    display: none;
    user-select: none;

    svg {
        vertical-align: baseline;
        fill: $color-wisteria;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
        svg {
            fill: $color-lavender;
        }
    }

    .l-square {
        box-shadow: $shadow-5;
    }

    @include at-min($bp-medium) {
        display: block;
    }
}

.glider-dots {
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;

    @include at-min($bp-medium) {
        display: none;
    }
}

.glider-dot {
    @include size($space-3);
    border-radius: 50%;
    margin: $space-1;
    background: $color-white;
    flex: none;
    padding: 0;

    &.active {
        background: $color-true-v;
    }
}
