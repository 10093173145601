.c-footer {
    background: $color-wild-sand;
    text-align: center;
    font-size: $font-size-2;
    min-height: $footer-height;
    content-visibility: auto;
    contain: layout paint;
    contain-intrinsic-block-size: $footer-height;
}

.c-footer__inner {
    padding-top: $space-5;
    padding-bottom: $space-5;
}

.c-footer__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.c-footer__social {
    margin: $space-2 0;
}

.c-footer__legal {
    line-height: $line-height-4;
}

.c-footer__link {
    padding: $space-2;
    line-height: $line-height-1;
    display: inline-block;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }
}
