$curve: svg-url(
    '<svg width="375" height="53" viewBox="0 0 375 53" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M375 0H0V52.2933C54.4581 18.7812 122.149 2 203.945 2C253.826 2 317.48 12.8438 375 47.8501V0Z" fill="white"/></svg>'
);

$wave: svg-url(
    '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1440 68"><path fill="#fff" fill-rule="evenodd" d="M1440 68V43.431c-.71-.142-1.41-.286-2.12-.431-361.67-74.332-664.271-36.042-918.027-3.931C317.142 64.719 145.598 86.427 0 43.628V68h1440Z" clip-rule="evenodd"/></svg>'
);

$wave-2: svg-url(
    '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 375 22"><path fill="#fff" fill-rule="evenodd" d="M254.855 0H0v18.555c42.828 5.625 89.104-.88 137.531-7.687C175.524 5.528 214.841.002 254.855 0Zm.036 0C294.447.002 334.685 5.404 375 21.723V0H254.891Z" clip-rule="evenodd"/></svg>'
);

.c-bg-shape--hero {
    position: relative;

    &:before {
        @include absolute(null 0 0 0);
        content: '';
        z-index: -1;
        user-select: none;
        pointer-events: none;
        background: $curve, $gradient-bg-1;
        background-position: top, center;
        background-size:
            100% auto,
            cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 49%;

        @include at-min($bp-small) {
            height: 62%;
        }

        @include at-min($bp-medium) {
            @include absolute(20% -50% auto auto);
            background: $gradient-bg-1 no-repeat auto auto;
            width: 100%;
            height: 0;
            padding-top: 100%;
            border-radius: 50%;
        }
    }
}

.c-bg-shape--wave {
    position: relative;
    background: $gradient-bg-2;
    padding-bottom: $space-14;
    margin-bottom: $space-14;

    &:after {
        @include absolute(0 0 -1px 0);
        content: '';
        user-select: none;
        pointer-events: none;
        background: $wave no-repeat;
        background-position: bottom left;
        background-size: 101% auto;
    }
}

.c-bg-shape--plans {
    background: $gradient-bg-3;
}

.c-bg-shape--testimonials {
    position: relative;
    background: $gradient-bg-4;
    padding-top: $space-14;
    padding-bottom: $space-14;

    &:after {
        @include absolute(0 0 -1px 0);
        content: '';
        user-select: none;
        pointer-events: none;
        background: $wave no-repeat;
        background-position: bottom left;
    }

    &:before {
        @include absolute(0 0 0 0);
        content: '';
        user-select: none;
        pointer-events: none;
        background: $wave-2 no-repeat;
        background-position: top left;
    }
}

.c-bg-shape--top {
    position: relative;
    background: $color-magnolia;
    padding-top: $space-14;
    padding-bottom: $space-10;

    &:before {
        @include absolute(0 0 0 0);
        content: '';
        user-select: none;
        pointer-events: none;
        background: $wave-2 no-repeat;
        background-position: top left;
    }
}

.c-bg-shape--testimonials-alt {
    position: relative;
    background: $gradient-bg-4;
    padding-top: $space-12;
    padding-bottom: $space-12;
    margin-top: $space-10;

    &:before {
        @include absolute(0 0 0 0);
        content: '';
        user-select: none;
        pointer-events: none;
        background: $wave-2 no-repeat;
        background-position: top left;
    }
}

.c-bg-shape--testimonials-alt2 {
    position: relative;
    background: $gradient-bg-4;
    padding-top: $space-12;
    padding-bottom: $space-12;

    &:after {
        @include absolute(0 0 -1px 0);
        content: '';
        user-select: none;
        pointer-events: none;
        background: $wave no-repeat;
        background-position: bottom left;
    }
}
