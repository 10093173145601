.c-title {
    font-size: $font-size-8;
    font-weight: $font-bold;
    line-height: $line-height-1;
    margin-bottom: 1em;

    @include at-min($bp-medium) {
        font-size: $font-size-10;
    }

    @include at-min($bp-large) {
        font-size: $font-size-11;
    }
}

.c-title,
.c-title--colored {
    span {
        display: block;
        color: $color-gun-powder;
    }

    span + span {
        color: $color-true-v;
    }
}

.c-title--inverted {
    span {
        display: block;
        color: $color-true-v;
    }

    span + span {
        color: $color-gun-powder;
    }
}

// @DEPRECATED
// Magic on-off to fit title on iPhone 6+ in single line
.c-title--join {
    @include at-max($bp-small) {
        font-size: $font-size-7;
    }
}
