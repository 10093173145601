@use 'sass:math';

// Breakpoint mixin
// @param {String} $breakpoint - Breakpoint value (usually via variables)
// @param {String} $respond - Breakpoint type (defaults to min-width)
// @requires $breakpoint
@mixin bp($breakpoint, $respond: min-width) {
    @media screen and ($respond: $breakpoint) {
        @content;
    }
}

// Breakpoint helper mixins
@mixin at-min($breakpoint) {
    @include bp(em($breakpoint, 16), min-width) {
        @content;
    }
}

@mixin at-max($breakpoint) {
    @include bp(em($breakpoint - 1, 16), max-width) {
        @content;
    }
}

// Mixin helping defining both `width` and `height` simultaneously.
// @param {Length} $width - Element’s `width`
// @param {Length} $height [$width] - Element’s `height`
@mixin size($width, $height: $width) {
    height: $height;
    width: $width;
}

// Bourbon.io positioning mixin
@mixin position($position: relative, $coordinates: null null null null) {
    @if type-of($position) == list {
        $coordinates: $position;
        $position: relative;
    }

    position: $position;

    $coordinates: unpack($coordinates);
    $top: nth($coordinates, 1);
    $right: nth($coordinates, 2);
    $bottom: nth($coordinates, 3);
    $left: nth($coordinates, 4);

    @if $top {
        top: $top;
    }

    @if $right {
        right: $right;
    }

    @if $bottom {
        bottom: $bottom;
    }

    @if $left {
        left: $left;
    }
}

// Positioning helper mixins
@mixin absolute($args) {
    @include position(absolute, $args);
}

@mixin fixed($args) {
    @include position(fixed, $args);
}

@mixin relative($args) {
    @include position(relative, $args);
}

// Advanced font rendering thingy
@mixin font-smoothing($val: on) {
    @if $val == 'on' {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else if $val == 'off' {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

// Shared disabled styles
@mixin disabled($opacity: 0.6) {
    opacity: $opacity;
    cursor: not-allowed;
}

// Center and limit main page content to optimal readable width.
@mixin main-content($chars: 60) {
    width: 100%;
    max-width: $chars * 1ch;
    margin: 0 auto;
}

// Make area vertically scrollable.
@mixin scrollable() {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

// Output border radius from radius map
@mixin radius($size) {
    border-radius: br($size);
}

// Triangle helper mixin
// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
// @param {Color} $color [currentcolor] - Triangle color
// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentcolor, $size: 1em) {
    @if not index(top right bottom left, $direction) {
        @error "Direction must be either `top`, `right`, `bottom` or `left`.";
    }

    width: 0;
    height: 0;
    content: '';
    z-index: 2;
    border-#{opposite-direction($direction)}: ($size * 1.5) solid $color;

    $perpendicular-borders: $size solid transparent;

    @if $direction == top or $direction == bottom {
        border-left: $perpendicular-borders;
        border-right: $perpendicular-borders;
    } @else if $direction == right or $direction == left {
        border-bottom: $perpendicular-borders;
        border-top: $perpendicular-borders;
    }
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    display: block;
    height: 0;
    padding-top: percentage(math.div($height, $width));

    > * {
        @include absolute(0);
    }
}

@mixin container() {
    max-width: $bp-large * 1px;
    margin: 0 auto;
    padding: 0 15px;

    @include at-min($bp-medium) {
        padding: 0 45px;
    }
}

// Apply ripple effect on the element
// @param color of the ripple or falsey to remove previously set ripple
// https://github.com/mladenplavsic/css-ripple-effect
// https://codepen.io/finnhvman/pen/jLXKJw
@mixin ripple($color) {
    @if $color {
        // Create stacking context
        // without setting z-index or
        // using transform hack.
        perspective: 1px;

        &:before {
            @include size(100%);
            @include absolute(0);
            content: '';
            border-radius: inherit;
            display: block;
            pointer-events: none;
            background-image: radial-gradient(circle, $color 1%, transparent 1%);
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 15000%;
            opacity: 0.01;
            transition:
                background 0.75s,
                opacity 0.65s;
            z-index: -1;
        }

        &:active:before {
            // Using floating point values for opacity
            // and animating background-size instead of transfrom scale
            // resolves bugs in Safari and older Chrome for Android.
            // https://stackoverflow.com/a/31698580/2382115
            // https://stackoverflow.com/a/55050203/2382115
            background-size: 100%;
            opacity: 0.99;
            transition: 0s;
        }
    } @else {
        @warn "Must pass color for ripple effect.";
    }
}

// Generate better-looking underline
@mixin underline() {
    text-decoration: underline;
    text-decoration-skip: objects;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: 1px;
}

// https://gist.github.com/matthewbeta/0ef11bd907b435f101e09cc6216ad66b
@mixin scrim-gradient($startColor: $color-black, $direction: 'to bottom') {
    $scrimCoordinates: (
        0: 1,
        19: 0.738,
        34: 0.541,
        47: 0.382,
        56.5: 0.278,
        65: 0.194,
        73: 0.126,
        80.2: 0.075,
        86.1: 0.042,
        91: 0.021,
        95.2: 0.008,
        98.2: 0.002,
        100: 0,
    );

    $hue: hue($startColor);
    $saturation: saturation($startColor);
    $lightness: lightness($startColor);
    $stops: ();

    @each $colorStop, $alphaValue in $scrimCoordinates {
        $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop * 0.01);
        $stops: append($stops, $stop, comma);
    }

    background: linear-gradient(unquote($direction), $stops);
}

// Center and limit main page content to optimal readable width.
@mixin content-box($width: 60ch) {
    width: 100%;
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
}

// Output type display based on design system
@mixin t-display($i) {
    @if type-of($i) != 'number' {
        @warn "Wrong argument, please pass a number between 0-7.";
    }

    @if $i == 0 {
        font-size: $font-size-0;
        line-height: $line-height-1;
        letter-spacing: -0.14px;
    }

    @if $i == 1 {
        font-size: $font-size-1;
        line-height: $line-height-4;
        letter-spacing: -0.14px;
    }

    @if $i == 2 {
        font-size: $font-size-2;
        line-height: $line-height-2;
        letter-spacing: -0.27px;
    }

    @if $i == 3 {
        font-size: $font-size-3;
        line-height: $line-height-3;
        letter-spacing: -0.33px;
    }

    @if $i == 4 {
        font-size: $font-size-4;
        line-height: $line-height-2;
        letter-spacing: -0.24px;
    }

    @if $i == 5 {
        font-size: $font-size-5;
        line-height: $line-height-1;
        letter-spacing: -0.24px;
    }

    @if $i == 6 {
        font-size: $font-size-6;
        line-height: $line-height-1;
        letter-spacing: -0.6px;
    }

    @if $i == 7 {
        font-size: $font-size-7;
        line-height: $line-height-1;
        letter-spacing: -0.7px;
    }
}

// https://snook.ca/archives/html_and_css/simplest-css-slideshow
@mixin fade($num: 1, $fade: 1, $visible: 2) {
    $a: math.div(100, ($fade + $visible) * $num);

    @keyframes fade {
        0% {
            opacity: 0;
        }
        #{$a * $fade}% {
            opacity: 1;
        }
        #{$a * ($fade + $visible)}% {
            opacity: 1;
        }
        #{$a * ($fade + $visible + $fade)}% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    opacity: 0;
    animation-name: fade;
    animation-duration: (($fade + $visible) * $num) + s;
    animation-iteration-count: infinite;

    @for $i from 1 through $num {
        &:nth-child(#{$i}) {
            animation-delay: #{($fade + $visible) * ($i - 1)}s;
        }
    }
}
