.c-phone-video {
    position: relative;
    cursor: pointer;
    display: inline-block;
}

.c-phone-video__overlay {
    @include at-max($bp-small) {
        .is-playing > & {
            @include fixed(0);
            z-index: z('video');
            opacity: 0;
            background-color: transparent;
            animation: overlay 300ms $modal-easing forwards;

            &:before {
                @include absolute($space-3 $space-3 null null);
                content: '✖';
                color: $color-white;
                font-size: $font-size-4;
            }
        }
    }
}

.c-phone-video__play-btn {
    @include absolute(50% 50% null null);
    transform: translateX(50%) translateY(-50%);

    padding: $space-3;
    box-shadow: $shadow-20;
    font-size: $font-size-6;
    background-color: $color-white;
    color: $color-gun-powder;
    z-index: z('play-btn');

    &[disabled] {
        color: $color-iron !important;
    }

    @include at-min($bp-small) {
        font-size: $font-size-9;
        padding: $space-5;
    }
}

.c-phone-video__shell {
    @include aspect-ratio(1, 1.85); // 1800x3340
    background-image: url('/assets/images/lottie-thumb-160@2x.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    width: 160px;
    transition: transform 0.3s ease-out;

    .is-playing > & {
        @include at-max($bp-small) {
            transform: scale(2);
            z-index: z('video');
        }
    }

    &:focus {
        outline: none;
    }

    @include at-min($bp-small) {
        width: 250px;
        background-image: url('/assets/images/lottie-thumb-250@2x.png');
    }

    // hack to avoid lottie animation fading in
    svg > g > g {
        opacity: 1 !important;
    }
}
