.c-media-item {
    display: flex;
    flex-direction: column;

    @include at-min($bp-medium) {
        margin-bottom: $space-14;
    }
}

.c-media-item__wrapper {
    margin-bottom: $space-4;

    @include at-min($bp-medium) {
        order: 99;
    }
}

.c-media-item__visual {
    margin-bottom: $space-10;
    min-height: 100px;

    img {
        width: 101px;
        height: auto;
    }

    @include at-max($bp-medium) {
        text-align: center;
    }
}

.c-media-item__title {
    font-size: $font-size-7;
    margin-bottom: $space-3;
    line-height: $line-height-1;

    span {
        display: block;
    }

    span + span {
        color: $color-true-v;
    }

    @include at-max($bp-medium) {
        text-align: center;
    }
}

.c-media-item__content {
    line-height: $line-height-3;

    @include at-max($bp-medium) {
        text-align: center;
    }
}

.c-media-item--alt {
    opacity: 0;
    animation: show 200ms ease-out forwards;

    @for $i from 3 through 5 {
        &:nth-child(#{$i}) {
            animation-delay: $i * 110ms + 50ms;
        }
    }

    .c-media-item__title {
        span {
            color: $color-gun-powder;
        }
        span:first-child {
            color: $color-wisteria;
        }
    }

    &.has-ribbon {
        .c-media-item__title {
            span:first-child {
                color: $color-gun-powder;
            }
            span:last-child {
                color: $color-wisteria;
            }
        }

        .c-media-item__visual span {
            position: relative;

            &:before,
            &:after {
                content: '';
                position: absolute;
                display: block;
                bottom: 0;
                border: 10px solid $color-gun-powder;
                z-index: -1;
            }

            &:before {
                left: 0;
                border-right-width: 15px;
                border-left-color: transparent;
                transform: rotate(-20deg);
            }

            &:after {
                right: 0;
                border-left-width: 15px;
                border-right-color: transparent;
                transform: rotate(20deg);
            }
        }
    }
}
