// https://www.smashingmagazine.com/2021/12/create-custom-range-input-consistent-browsers/
// https://www.quirksmode.org/blog/archives/2015/11/styling_and_scr.html

$knob: svg-url(
    '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><circle cx="16" cy="16" r="14" /><path stroke="#F9FAF9" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19.207 18.063l2.301-2.532-2.3-2.531M13.301 13L11 15.531l2.301 2.531"/></svg>'
);

.c-slider {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    width: 100%;

    &:focus-visible {
        outline: 2px dashed $color-wisteria;
        outline-offset: 0.125rem;
    }

    &:before {
        @include absolute(0 null null 0);
        content: '';
        width: calc(var(--progress) * 1%);
        height: 100%;
        background: $gradient-base;
        border-radius: 0.5rem;
    }

    &::-webkit-slider-runnable-track {
        background: $color-athens-gray;
        border-radius: 0.5rem;
        height: 0.5rem;
    }

    &::-moz-range-track {
        background: $color-athens-gray;
        border-radius: 0.5rem;
        height: 0.5rem;
    }

    &::-webkit-slider-thumb {
        @include size($font-size-6);
        -webkit-appearance: none;
        appearance: none;
        border-radius: 50%;
        transform: translateY(
            negative($space-2)
        ); // (track height in pixels / 2) - (thumb height in pixels /2)
        background: $knob, $gradient-base;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    &::-webkit-slider-thumb {
        @include size($font-size-6);
        border: 0;
        border-radius: 50%;
        background: $knob, $gradient-base;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
}
