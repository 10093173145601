.c-steps {
    margin-bottom: $space-10;

    @include at-max($bp-medium) {
        text-align: center;
        max-width: $bp-tiny * 1px;
        margin-left: auto;
        margin-right: auto;
    }

    @include at-min($bp-medium) {
        display: flex;
        gap: $space-3;

        .c-steps__step {
            flex: 1 1 30%;
            padding-right: $space-4;
        }
    }
}

.c-steps__step:not(:last-child) {
    position: relative;
    display: block;

    &:after {
        position: relative;
        content: '';
        display: block;
        height: $space-12;
        width: 1px;
        left: calc(50% - 1px);
        margin-bottom: $space-3;
        background: repeating-linear-gradient(
            $color-true-v,
            $color-true-v 5px,
            $color-white 5px,
            $color-white 7px
        );
        background-size: auto 100%;
        background-repeat: no-repeat;

        @include at-min($bp-medium) {
            height: 1px;
            width: 100%;
            left: 0;
            top: $space-6;
            position: absolute;
            z-index: -1;
            background: repeating-linear-gradient(
                90deg,
                $color-true-v,
                $color-true-v 5px,
                $color-white 5px,
                $color-white 7px
            );
            background-size: 100% auto;
            background-repeat: no-repeat;
        }

        @include at-min($bp-large) {
            top: 50px;
        }
    }
}

.c-steps__number {
    font-size: $font-size-7;
    font-weight: $font-bold;
    background: $gradient-base;
    color: $color-white;
    width: $space-12;
    margin-bottom: $space-4;

    @include at-min($bp-medium) {
        box-shadow: 0 0 0 $space-2 $color-white;
        margin-bottom: $space-6;
    }

    @include at-min($bp-large) {
        font-size: $font-size-11;
        margin-bottom: $space-10;
        width: 100px;
    }
}

.c-steps__title {
    font-size: $font-size-4;
    font-weight: $font-bold;
    color: $color-true-v;
    margin-bottom: $space-1;
    line-height: $line-height-1;

    @include at-min($bp-medium) {
        font-size: $font-size-7;
        margin-bottom: $space-2;
    }
}
