// Styles related to input field element.

$input-border-base: $color-athens-gray;
$input-error: $color-carnation;

.c-input {
    position: relative;
}

.c-input__field {
    position: relative;
    width: 100%;
    display: block;
    cursor: pointer;
    transition: all 0.2s;

    // look and feel
    appearance: none;
    padding: $space-3;
    color: $color-gun-powder;
    background-color: $color-alabaster;
    border: 1px solid $color-gainsboro;
    border-radius: br('small');
    font-weight: $font-bold;
    line-height: $line-height-4;

    &:focus {
        border: 1px solid $color-wisteria !important;
        outline: none;
        cursor: auto;
    }

    &[disabled]:not(.c-input__field--readonly),
    &:disabled:not(.c-input__field--readonly) {
        @include disabled();
    }

    &.has-error,
    .has-error:not(.c-input--autocomplete) & {
        border-color: $input-error;

        &:focus {
            border-color: $input-error;
        }
    }

    .c-input__group & {
        flex: 1 1 100%;
    }

    .c-input__prefix + & {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    // Floating placeholder
    .has-placeholder & {
        &:not(:placeholder-shown),
        &:focus {
            padding-top: $space-4;
            padding-bottom: $space-2;

            & ~ .c-input__placeholder {
                transform: translateY(negative($space-2));
                font-size: $font-size-1;
                line-height: $line-height-1;
            }
        }
    }

    .c-input--alt-color & {
        background-color: $color-white;
        border-color: $color-athens-gray;
    }

    .c-input--alt-weight & {
        font-weight: $font-medium;
    }

    .c-input--center-value & {
        text-align: center;
        padding-left: $space-1;
        padding-right: $space-1;
    }

    &::placeholder {
        opacity: 0;
    }
}

.c-input__field input {
    width: 100%;
    font-weight: $font-bold;
    color: $color-gun-powder;
    padding: 0;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: $color-gun-powder;
        font-weight: $font-medium;
    }
}

.c-input__label {
    display: block;
    margin-bottom: $space-2;

    .c-input--alt-weight & {
        font-weight: $font-bold;
    }
}

.c-input__placeholder {
    @include absolute(0);
    padding: $space-3;
    border: 1px solid transparent;
    pointer-events: none;
    color: $color-french-gray;
    font-weight: $font-medium;
    line-height: $line-height-4;
    transition: all 0.2s;
    will-change: font-size, line-height, transform;
}

.c-input__group {
    display: flex;
    width: 100%;

    .c-input__prefix {
        display: flex;
        align-items: center;
        font-weight: $font-bold;
        font-size: $font-size-3;
        padding: $space-3;
        text-align: center;
        background-color: $input-border-base;
    }
}

.c-input__prefix {
    border-top-left-radius: br('small');
    border-bottom-left-radius: br('small');
}

.c-input__error {
    color: $input-error;
    margin-top: $space-1;

    &:empty {
        display: none;
    }
}

// Wrapper for <select> element, all other input classes apply as usual
.c-input--select {
    .c-input__field {
        padding-right: $space-10;

        // select element must be required for this style to apply
        // on the placeholder option. No idea why
        &:invalid {
            color: $color-french-gray;
            font-weight: $font-medium;
        }
    }

    option {
        // Need to re-define color here so dropdown
        // options are always visible and don't
        // inherit select:invalid
        color: $color-gun-powder;
        font-weight: $font-medium;
    }
}

.c-input__icon {
    @include absolute(50% $space-3 null null);
    transform: translateY(-50%);
    color: $color-wisteria;
    font-size: $font-size-5;
    pointer-events: none;
}

.c-input--group {
    input {
        flex: 1 1 auto;
        min-width: 0;
    }

    & *:not(input) {
        flex: none;
    }
}

.c-input--readonly {
    label {
        cursor: default;
    }

    .c-input__field {
        cursor: default;
    }
}
