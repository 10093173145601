// Class for adding spinner over element.

$color-spinner: $color-brilliant-rose;

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.c-spinner {
    position: relative;
    transform: translateZ(0);
    pointer-events: none;
    opacity: 0.6;

    &:after {
        content: '';
        display: inline-block;

        // size and position
        @include size(1em);
        @include absolute(50% 50% null null);
        margin-top: -0.5em;
        margin-right: -0.5em;

        border-radius: br('circle');
        border: 2px solid rgba($color-white, 0.8);
        border-top-color: darken($color-spinner, 20%);

        z-index: z('spinner');
        animation: spin 0.4s linear infinite;
    }
}
