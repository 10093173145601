.c-card {
    position: relative;
    background: $color-white;
    border-radius: br('medium');
    border: 4px solid transparent;
    padding: $space-4;
    margin-bottom: $space-3;

    &.is-active {
        &.is-flex {
            border-color: $color-wisteria;

            .c-card__title {
                color: $color-wisteria;
            }
        }
        &.is-unlimited {
            border-color: $color-true-v;

            .c-card__title {
                color: $color-true-v;
            }
        }
    }
}

.c-card__media {
    display: flex;
    align-items: flex-start;
    margin-bottom: $space-3;

    > img {
        flex: none;
        margin-right: $space-3;
        margin-top: $space-1;
    }

    > *:not(img) {
        flex: 1 1 auto;
    }
}

.c-card__header {
    @include at-min($bp-medium) {
        min-height: $spacing-base * 30; // 150px
    }

    @include at-min($bp-large) {
        min-height: $spacing-base * 34; // 170px
    }
}

.c-card__title {
    font-size: $font-size-4;
    font-weight: $font-bold;
    line-height: $line-height-3;
    margin-bottom: 0;

    @include at-min($bp-large) {
        letter-spacing: track(15);
        font-size: $font-size-6;
    }
}

.c-card__subtitle {
    margin-bottom: $space-3;

    @include at-min($bp-large) {
        font-size: $font-size-3;
    }
}

.c-card__content-icon {
    width: $space-6;
}

.c-cs .c-card__title {
    font-size: $font-size-3;
}

.c-cs .c-card__content {
    font-size: $font-size-2;
}

.c-card__icon {
    color: $color-brilliant-rose;
    transition: all 0.2s;
}

// Applied on <button> and <a> elements.
.c-card--active {
    @include ripple($color-iron);

    &:hover,
    &:focus {
        outline: none;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus {
            background-color: $color-athens-gray;

            .c-card__icon {
                color: $color-royal-heath;
            }
        }
    }
}

a.c-card {
    cursor: pointer;
    color: $color-purple-dark;
    text-decoration: none;
}

.c-card--white {
    background: $color-white;
    border: $color-white;
}
