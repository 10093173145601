@keyframes show {
    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes overlay {
    to {
        opacity: 1;
        background-color: rgba($color-gun-powder, 0.75);
    }
}
