// COLORS

$color-black: hsl(0deg 0% 20%);
$color-white: #ffffff;

$color-gun-powder:          #434254; // purple darkest
$color-purple-heart:        #5b45c9; // purple dark
$color-true-v:              #6e66db; // purple base
$color-biloba-flower:       #a79fef; // purple light
$color-mischka:             #dcdce6; // purple gray
$color-link-water:          #f0effb; // purple lightest
$color-white-lilac:         #f8f8fd; // link-water at 50%
$color-white-cs:            #f0f1fb;
$color-wisp-pink:           #fdedf5; // pink light

$color-storm-gray:          #72727f;
$color-santas-gray:         #a1a0a9;
$color-iron:                #d0d0d4;
$color-athens-gray:         #ececed;
$color-alabaster:           #f9f9f9;
$color-french-gray:         #c1c1c6; // gun-powder at 30%

$color-carnation:           #f45265; // red
$color-purple-dark:         hsl(243deg 12% 29%);

// NEW COLORS

$color-eminence:             #832884;
$color-wisteria:             #b95dba;
$color-light-wisteria:       #d59ed6;
$color-melanie:              #eaceea;
$color-lavender:             #e7e6f8;
$color-wild-sand:            #f6f6f6;
$color-magnolia:             #f3f3fc;
$color-anti-white:           #f1f0fb;
$color-gainsboro:            #d9d9dd; // gray 20%

// gradient-only colors
$color-fuchsia-blue:         #8345c9; // gradient dark start point
$color-royal-heath:          #a12a65; // gradient dark end point
$color-brilliant-rose:       #ed56a1; // gradient base end point

// GRADIENTS

$gradient-base: linear-gradient(97deg, $color-true-v, $color-brilliant-rose 160%);
$gradient-dark: linear-gradient(97deg, $color-fuchsia-blue, $color-royal-heath 160%);

$gradient-bg-1: linear-gradient(0deg, $color-lavender, $color-alabaster);
$gradient-bg-2: linear-gradient(0deg, $color-lavender, $color-white);
$gradient-bg-3: linear-gradient(0deg, $color-magnolia, $color-white);
$gradient-bg-4: linear-gradient(0deg, $color-lavender, $color-magnolia);

// SHADOWS

$shadow-5: 1px 1px 2px rgba($color-black, 0.1); // nav arrows
$shadow-10: 2px 2px 8px rgba($color-black, 0.1); // header, slider nav
$shadow-20: 4px 8px 10px rgba($color-black, 0.1); // play btn
$shadow-25: 4px 4px 12px rgba($color-black, 0.1); // quote
$shadow-30: 8px 8px 20px rgba($color-black, 0.1); // cta and zendesk
$shadow-40: 15px 25px 20px rgba($color-black, 0.25); // hhw
$shadow-50: 0 10px 50px rgba($color-black, 0.05); // media item

$shadow-200: 0 2px 4px rgba($color-black, 0.1); // small buttons
$shadow-300: 0 2px 8px rgba($color-black, 0.05); // large buttons
$shadow-500: 2px 2px 8px rgba($color-black, 0.2); // help icon
