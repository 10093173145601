.c-social {
    a {
        display: inline-flex;
        padding: $space-2;
        font-size: $font-size-5;
        color: $color-wisteria;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            color: $color-eminence;
        }
    }
}

.c-social--inverted {
    a {
        color: $color-white;

        &:hover,
        &:focus,
        &:active {
            color: $color-wisteria;
        }
    }
}
